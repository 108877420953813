<template>
    <ion-page>
        <ThanhTieuDeVue>
            <template #before>
                <div class="row align-center justify-space-between">
                    <DxButton
                        type="default"
                        icon="mdi mdi-chevron-left"
                        class="ml-1"
                        @click="$router.push('/tabs/HangHoa')"
                    />
                    XEM CHI TIẾT
                    <DxButton
                        type="default"
                        icon="mdi mdi-chevron-left"
                        class="ml-1"
                        style="opacity: 0"
                    />
                </div>
            </template>
        </ThanhTieuDeVue>
        <ion-content :scroll-events="false">
            <div class="frame-content">
                <div class="row frame-data">
                    <div
                        class="trang-thai"
                        :style="`color: ${ChiTietHang.maMauThanhToan}; border-color: ${ChiTietHang.maMauThanhToan}`"
                        v-if="ChiTietHang.trangThaiThanhToan"
                    >
                        {{ ChiTietHang.trangThaiThanhToan }}
                    </div>
                    <div class="xs12">
                        <div class="row justify-center" v-if="ChiTietHang.linkTraCuu">
                            <div class="qrcodes">
                                <canvas
                                    :id="`qrcodeTraCu`"
                                    style="
                                        width: 100% !important;
                                        height: 100% !important;
                                    "
                                ></canvas>
                            </div>
                        </div>
                        <div
                            v-else
                            class="row justify-center align-center"
                            style="
                                width: 144px;
                                height: 144px;
                                border: 1px solid #dadce0;
                                border-radius: 8px;
                                margin: 0 auto;
                                margin-bottom: 8px;
                            "
                        >
                            Không có QR Code!
                        </div>

                        <div class="row mb-1">
                            <div
                                class="xs12 font-medium text-xs-center"
                                :style="`color: ${ChiTietHang.maMauVanChuyen}`"
                            >
                                {{ ChiTietHang.trangThaiVanChuyen }}
                                <span
                                    class="font-italic font-regular font-12"
                                    v-if="
                                        !ChiTietHang.thoiGianGiaoHangThucTe &&
                                        ChiTietHang.thoiGianGiaoHangDuKien
                                    "
                                >
                                    (Dự kiến:
                                    {{
                                        $Helper.formatDateTime(
                                            ChiTietHang.thoiGianGiaoHangDuKien,
                                        )
                                    }})</span
                                >
                                <span
                                    v-else-if="ChiTietHang.thoiGianGiaoHangThucTe"
                                    class="font-italic font-regular font-12"
                                >
                                    ({{
                                        $Helper.formatDateTime(
                                            ChiTietHang.thoiGianGiaoHangThucTe,
                                        )
                                    }})
                                </span>
                            </div>
                        </div>

                        <div :class="rowFrame">
                            <i :class="iconLeft" class="mdi-cash-multiple"></i>
                            Cước: {{ $MoneyFormat(ChiTietHang.tongTien) }}đ
                        </div>
                        <div :class="rowFrame">
                            <i :class="iconLeft" class="mdi-barcode"></i>
                            Mã tra cứu: {{ ChiTietHang.maTraCuu }}
                        </div>
                        <div :class="rowFrame" style="white-space: nowrap">
                            <i :class="iconLeft" class="mdi-link"></i>
                            Link tra cứu:
                            <a
                                :href="ChiTietHang.linkTraCuu"
                                class="ml-1"
                                style="overflow: hidden; text-overflow: ellipsis"
                            >
                                {{ ChiTietHang.linkTraCuu }}</a
                            >
                        </div>
                        <div :class="rowFrame">
                            <i :class="iconLeft" class="mdi-map-marker-radius"></i>
                            Trả tại: {{ ChiTietHang.tenDiemNhanHang }}
                        </div>
                        <div :class="rowFrame">
                            <i :class="iconLeft" class="mdi-email-receive-outline"></i>
                            SĐT người nhận:
                            {{ $Helper.formatPhone(ChiTietHang.soDienThoaiNhan) }}
                        </div>
                        <div :class="rowFrame">
                            <i :class="iconLeft" class="mdi-email-send-outline"></i>
                            SĐT người gửi:
                            {{ $Helper.formatPhone(ChiTietHang.soDienThoaiGui) }}
                        </div>

                        <div class="row">
                            <ListAnhChupVue
                                :ArrPreviewImg="ArrPreviewImg"
                                :Edit="false"
                            />
                        </div>
                    </div>
                </div>

                <div class="frame-button" style="">
                    <div class="row justify-space-between align-center">
                        <ion-button color="primary" fill="clear" @click="layThongTinVe()">
                            In phiếu
                        </ion-button>
                        <ion-button
                            :color="
                                ChiTietHang.thoiGianGiaoHangThucTe ? 'dark' : 'primary'
                            "
                            fill="solid"
                            expand="block"
                            @click="TraHang()"
                            :disabled="ChiTietHang.thoiGianGiaoHangThucTe"
                        >
                            Trả hàng
                        </ion-button>
                    </div>
                </div>
            </div>
        </ion-content>

        <!-- ====================================Popup Trả hàng ========================================= -->
        <PopupVue
            v-model:dialog="dialogTraHang"
            title=""
            :fullWidth="false"
            :useButtonLeft="false"
            :useButtonRight="false"
            :column="false"
            classPopup="popup-padding-bottom-0"
        >
            <template #content>
                <TraHangVue
                    :ThongTinTraHang="ThongTinTraHang"
                    @TraHangThanhCong="TraHangThanhCong"
                />
            </template>
        </PopupVue>
    </ion-page>
</template>

<script>
import {
    IonContent,
    IonPage,
    onIonViewWillEnter,
    IonButton,
    onIonViewWillLeave,
} from "@ionic/vue";
import ThanhTieuDeVue from "../../../components/_Common/ThanhTieuDe.vue";
import { DxButton } from "devextreme-vue";
import { mapActions } from "vuex";
import ListAnhChupVue from "../components/ListAnhChup.vue";
import TraHangVue from "../components/TraHang.vue";
import PopupVue from "../../../components/_Common/Popup.vue";
var QRCode = require("qrcode");
export default {
    components: {
        IonContent,
        IonPage,
        DxButton,
        ThanhTieuDeVue,
        ListAnhChupVue,
        IonButton,
        TraHangVue,
        PopupVue,
    },
    data() {
        return {
            ChiTietHang: {},
            qrcode: null,
            rowFrame: "row align-center mb-1",
            iconLeft: "mr-2 mdi",
            id: null,
            ArrPreviewImg: [],
            dialogTraHang: false,
        };
    },
    methods: {
        ...mapActions("HangHoa", ["LayChiTietHang"]),
        async getData() {
            let toast = {
                type: "error",
                visible: true,
            };
            this.ChiTietHang = {};
            this.ArrPreviewImg = [];
            try {
                let query = this.$route.query;
                let rs = await this.LayChiTietHang(query);

                if (rs.Data.status == true) {
                    this.ChiTietHang = rs.Data.data;
                    if (this.ChiTietHang.danhSachHinhAnh.length > 0) {
                        for (
                            let index = 0;
                            index < this.ChiTietHang.danhSachHinhAnh.length;
                            index++
                        ) {
                            const e = this.ChiTietHang.danhSachHinhAnh[index];
                            let rs = await this.$Core.Api.HangHoa(
                                this.$t("urlFile.GetFileImg"),
                                {
                                    idFile: e,
                                },
                            )
                                .Config((c) => {
                                    c.responseType = "blob";
                                    c.validateStatus = () => true;
                                })
                                .Get();
                            let link = URL.createObjectURL(rs.Data);
                            this.ArrPreviewImg.push(link);
                        }
                    }
                    setTimeout(() => {
                        this.getQrCode();
                    }, 500);
                } else {
                    toast.message = rs.Data.message;
                    this.emitter.emit("onToast", toast);
                    this.$router.push({
                        path: "/tabs/HangHoa",
                    });
                }
            } catch (error) {
                toast.message = error;
                this.emitter.emit("onToast", toast);
                this.$router.push({
                    path: "/tabs/HangHoa",
                });
            }
        },
        getQrCode() {
            QRCode.toCanvas(
                document.getElementById("qrcodeTraCu"),
                this.ChiTietHang.linkTraCuu,
                { errorCorrectionLevel: "L", margin: 2 },
                function (error) {
                    if (error) console.error(error);
                    //success
                },
            );
        },
        async layThongTinVe() {
            let toast = {
                type: "error",
                visible: true,
            };
            try {
                let rs = await this.$AppClass.InVe(
                    this.$i18n.t("url.InVeTheoIdNhatKyGuiHang"),
                    "IdNhatKy",
                    this.$route.query.idNhatKy || null,
                );
                if (rs == "error") {
                    toast.message = "In thất bại!";
                    this.emitter.emit("onToast", toast);
                }
                this.$stopLoading;
            } catch (error) {
                this.$stopLoading;
                console.error(error);
            }
        },
        TraHang() {
            let item = { ...this.ChiTietHang };
            item.idNhatKy = this.$route.query.idNhatKy;
            this.ThongTinTraHang = item;
            this.dialogTraHang = true;
        },
        TraHangThanhCong() {
            setTimeout(() => {
                this.dialogTraHang = false;
                this.getData();
            }, 500);
        },
        closeAllDialog() {
            this.dialogTraHang = false;
        },
    },
    created() {
        onIonViewWillEnter(() => {
            this.getData();
        });
    },
    mounted() {
        onIonViewWillLeave(() => {
            this.closeAllDialog();
        });
    },
};
</script>

<style scoped>
.qrcodes {
    width: 144px;
    height: 144px;
}
.trang-thai {
    position: absolute;
    top: 8px;
    right: -64px;
    transform: rotate(45deg);
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: 4px 0;
    width: 172px;
    text-align: center;
    z-index: 1;
    opacity: 0.5;
}
.frame-content {
    padding: 16px;
    position: relative;
}

.frame-data {
    padding-bottom: 56px;
    position: relative;
}
.frame-button {
    border-top: 1px solid rgb(218, 220, 224);
    background: #fff;
    position: fixed;
    bottom: 0;
    width: 100vw;
    z-index: 9;
    left: 0;
    padding: 4px 8px;
}
</style>
