<template>
    <div class="row" style="height: 256px; max-height: 288px">
        <div class="xs12">
            <div class="row justify-center font-20 font-medium">Xác nhận trả hàng</div>
            <div class="row justify-center font-16 mb-2">
                Bạn có muốn thực hiện trả hàng?
            </div>

            <div class="row justify-space-between align-center mb-2">
                <div class="grow">Ảnh: {{ ArrPreviewImg.length }}/2</div>
                <div class="shrink">
                    <div class="row align-center">
                        <ion-checkbox v-model="CheckInVe"></ion-checkbox>
                        <span class="font-14 ml-2">In phiếu</span>
                    </div>
                </div>
            </div>

            <div class="row">
                <ListAnhChupVue
                    v-model:ArrPreviewImg="ArrPreviewImg"
                    v-model:ArrImgPush="ArrImgPush"
                    :SoLuongAnh="2"
                />
            </div>

            <div class="row mt-3 justify-end py-2" style="border-top: 1px solid #dadce0">
                <ion-button
                    color="primary"
                    fill="solid"
                    expand="block"
                    @click="XacNhanTraHang()"
                    :disabled="disabledXacNhan"
                >
                    Xác nhận
                </ion-button>
            </div>
        </div>
    </div>
</template>

<script>
import { IonButton, IonCheckbox, onIonViewWillLeave } from "@ionic/vue";
import { mapActions, mapState } from "vuex";
import ListAnhChupVue from "./ListAnhChup.vue";
export default {
    components: {
        IonButton,
        IonCheckbox,
        ListAnhChupVue,
    },
    props: {
        ThongTinTraHang: { type: Object, default: () => {} },
        dialogTraHang: { type: Boolean, default: false },
    },
    data() {
        return {
            location: null,
            CheckInVe: false,
            ArrPreviewImg: [],
            ArrImgPush: [],
            disabledXacNhan: false,
        };
    },
    computed: {
        ...mapState({
            ToaDo: (state) => state.ThongTin.ToaDo,
        }),
    },
    watch: {
        dialogTraHang: {
            handler: function (val) {
                if (val) {
                    this.clearData();
                }
            },
        },
    },
    methods: {
        ...mapActions("HangHoa", ["TraHangHoa", "CapNhatHinhAnhVanChuyenHang"]),
        async XacNhanTraHang() {
            this.disabledXacNhan = true;
            this.$startLoading;
            let toast = {
                type: "error",
                visible: true,
            };
            try {
                let query = {
                    idNhatKy: this.ThongTinTraHang.idNhatKy,
                    toaDo: this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                    danhSachHinhAnh: this.ArrImgPush,
                };
                let rs = await this.TraHangHoa(query);
                this.disabledXacNhan = false;
                if (rs.Data.status == true) {
                    toast.type = "success";
                    toast.message = "Trả hàng thành công!";
                    this.emitter.emit("onToast", toast);
                    // if (this.ArrImgPush.length > 0) {
                    //     this.CapNhatHinhAnhHangHoa(
                    //         this.ThongTinTraHang.idNhatKy
                    //     );
                    // } else {
                    this.$emit("TraHangThanhCong");
                    // }
                    if (this.CheckInVe) {
                        this.layThongTinVe(this.ThongTinTraHang.idNhatKy);
                    }
                    this.clearData();
                } else {
                    toast.message = rs.Data.message;
                    this.emitter.emit("onToast", toast);
                }
            } catch (error) {
                this.disabledXacNhan = false;
                console.log("🚀 ~ error", error);
                toast.message = error;
                this.emitter.emit("onToast", toast);
            } finally {
                this.$stopLoading;
            }
        },
        clearData() {
            this.location = null;
            this.CheckInVe = false;
            this.disabledXacNhan = false;
            this.ArrPreviewImg = [];
            this.ArrImgPush = [];
            var fileInput = document.getElementById("input-file");
            if (fileInput) {
                fileInput.value = "";
            }
        },
        // getLocation() {
        //     let self = this;
        //     return new Promise((resolve, reject) => {
        //         navigator.geolocation.getCurrentPosition(
        //             ({ coords }) => {
        //                 self.location = {
        //                     latitude: coords.latitude,
        //                     longitude: coords.longitude,
        //                 };
        //                 resolve(self.location);
        //             },
        //             (err) => {
        //                 console.log("🚀 ~  err", err);
        //                 resolve("null");
        //             },
        //             { timeout: 3000, enableHighAccuracy: true }
        //         );
        //     });
        // },
        async layThongTinVe(idNhatKy) {
            let toast = {
                type: "error",
                visible: true,
            };
            try {
                let rs = await this.$AppClass.InVe(
                    this.$i18n.t("url.InVeTheoIdNhatKyGuiHang"),
                    "IdNhatKy",
                    idNhatKy,
                );
                if (rs == "error") {
                    setTimeout(() => {
                        toast.message = "In thất bại!";
                        this.emitter.emit("onToast", toast);
                    }, 4000);
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.$stopLoading;
            }
        },
    },
    mounted() {
        onIonViewWillLeave(() => {
            this.clearData();
        });
    },
};
</script>

<style scoped></style>
